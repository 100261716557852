(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"), require("./EMFJS.bundle.js"), require("./WMFJS.bundle.js"));
	else if(typeof define === 'function' && define.amd)
		define(["jquery", "./EMFJS.bundle.js", "./WMFJS.bundle.js"], factory);
	else if(typeof exports === 'object')
		exports["RTFJS"] = factory(require("jquery"), require("./EMFJS.bundle.js"), require("./WMFJS.bundle.js"));
	else
		root["RTFJS"] = factory(root["$"], root["EMFJS"], root["WMFJS"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__30__) {
return 